import { ContentFull, ContentHeader, ContentSubheader } from "components/layout";
import { PhoneInput } from "components/shared/phone-input";
import { AutosavingCheckbox } from "components/ui/autosaving-checkbox";
import { AutosavingInput } from "components/ui/autosaving-input";
import { ReorderableStringList } from "components/ui/reorderable-string-list";
import {
    PatchUserProfileRequest,
    useGetUserProfileQuery,
    usePatchUserProfileMutation,
    UserProfileStatus,
} from "generated/graphql";
import React, { useCallback } from "react";
import { useProductName } from "store/selectors/branding";
import { useFeature } from "store/selectors/hooks";
import { UserTerritorySettings } from "./user-territory-settings";

export const UserProfileSettings: React.FC<unknown> = _ => {
    const isEnabled = useFeature("publicProfile");

    const productName = useProductName();

    const userProfile = useGetUserProfileQuery({}, { keepPreviousData: true });
    const patchUserProfile = usePatchUserProfileMutation();

    const doUpdate = useCallback(
        async (params: PatchUserProfileRequest) => {
            await patchUserProfile.mutateAsync({
                params,
            });
            userProfile.refetch();
        },
        [patchUserProfile, userProfile],
    );

    const handleShowInDirectoryChange = useCallback(
        async (v: boolean) => {
            await doUpdate({
                status: v ? UserProfileStatus.Public : UserProfileStatus.Private,
            });
        },
        [doUpdate],
    );

    const handleNameChange = useCallback(
        async (v: string) => {
            await doUpdate({
                name: v,
            });
        },
        [doUpdate],
    );

    const handleEmailChange = useCallback(
        async (v: string) => {
            await doUpdate({
                email: v,
            });
        },
        [doUpdate],
    );

    const handlePhoneChange = useCallback(
        async (v: string) => {
            await doUpdate({
                phone: v,
            });
        },
        [doUpdate],
    );

    const handleSummaryChange = useCallback(
        async (v: string) => {
            await doUpdate({ summary: v });
        },
        [doUpdate],
    );

    const handleApproachChange = useCallback(
        async (v: string) => {
            await doUpdate({ approach: v });
        },
        [doUpdate],
    );

    const handleCertificationsChange = useCallback(
        async (v: string[]) => {
            await doUpdate({ certifications: v });
        },
        [doUpdate],
    );

    if (!isEnabled || !userProfile.data) {
        return null;
    }

    return (
        <ContentFull>
            <ContentHeader>Public profile</ContentHeader>
            <ContentSubheader>
                Configure your public profile so that other users in the {productName}{" "}
                network can find you.
            </ContentSubheader>
            <div className="space-y-4">
                <AutosavingCheckbox
                    label={`Share my information below in the ${productName} directory`}
                    initial={
                        userProfile.data.getUserProfile.status ===
                        UserProfileStatus.Public
                    }
                    onCommit={handleShowInDirectoryChange}
                />
                <AutosavingInput
                    label="Name"
                    initial={userProfile.data.getUserProfile.name}
                    onCommit={handleNameChange}
                />
                <AutosavingInput
                    label="Email"
                    initial={userProfile.data.getUserProfile.email}
                    onCommit={handleEmailChange}
                />
                <PhoneInput
                    label="Phone"
                    initial={userProfile.data.getUserProfile.phone}
                    onCommit={handlePhoneChange}
                />
                <AutosavingInput
                    label="Summary"
                    multiLine
                    initial={userProfile.data.getUserProfile.summary}
                    onCommit={handleSummaryChange}
                    maxLength={300}
                    placeholder="In 2-3 sentences Describe your professional background and expertise"
                />
                <AutosavingInput
                    label="Approach"
                    multiLine
                    initial={userProfile.data.getUserProfile.approach}
                    onCommit={handleApproachChange}
                    placeholder="In 3-4 sentences, describe your approach to working with clients"
                    maxLength={500}
                />
                <ReorderableStringList
                    label="Certifications"
                    addButtonLabel="Add certification"
                    initial={userProfile.data.getUserProfile.certifications}
                    onCommit={handleCertificationsChange}
                />
                <UserTerritorySettings
                    territories={userProfile.data.getUserProfile.territories}
                    refetch={userProfile.refetch}
                />
            </div>
        </ContentFull>
    );
};
