import {
    InvalidOperationException,
    LocalizedDistance,
    LocationSearchMatchType,
    Meters,
    singleLineAddress,
} from "@sp-crm/core";
import { SearchGeoParamsEditor } from "components/community-search/search-bar/search-geo-params-callout";
import { useLocalizedStrings } from "components/locale-provider/locale-provider";
import { SecondaryButton } from "components/ui/secondary-button";
import {
    GetUserProfileQuery,
    SearchGeoParams,
    UpsertUserTerritoryRequest,
    UserTerritoryType,
    useUpsertUserTerritoryMutation,
} from "generated/graphql";
import React, { useCallback, useMemo, useState } from "react";

interface UserTerritorySettingsProps {
    territories: GetUserProfileQuery["getUserProfile"]["territories"];
    refetch: () => void;
}

export const UserTerritorySettings: React.FC<UserTerritorySettingsProps> = props => {
    const { refetch, territories } = props;
    const [editing, setEditing] = useState(false);

    const handleChangeTerritory = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            setEditing(true);
        },
        [],
    );

    const handleClose = React.useCallback(() => {
        setEditing(false);
    }, []);

    const territory: GetUserProfileQuery["getUserProfile"]["territories"][number] | null =
        useMemo(() => territories[0] || null, [territories]);

    return (
        <div className="space-y-2">
            <p>Service area</p>
            <p className="text-gray-500 text-sm group-hover:text-gray-900">
                Set your service area so others can find and refer clients to you
            </p>
            {editing ? (
                <UserTerritoryForm
                    territory={territory}
                    onClose={handleClose}
                    refetch={refetch}
                />
            ) : (
                <UserTerritoryPreview territory={territory} />
            )}
            <SecondaryButton className="text-sm" onClick={handleChangeTerritory}>
                Change area
            </SecondaryButton>
        </div>
    );
};

interface UserTerritoryPreviewProps {
    territory: GetUserProfileQuery["getUserProfile"]["territories"][number];
}

const UserTerritoryPreview: React.FC<UserTerritoryPreviewProps> = props => {
    const { territory } = props;

    const locale = useLocalizedStrings();

    if (!territory) {
        return <div className="text-sm">No service area has been configured.</div>;
    }

    switch (territory.type) {
        case UserTerritoryType.Proximity:
            return (
                <div>
                    Within{" "}
                    {Math.floor(
                        locale.conversions.fromMeters(territory.distance as Meters),
                    )}{" "}
                    {territory.distance > 1
                        ? locale.strings.distanceUnitPlural
                        : locale.strings.distanceUnit}{" "}
                    of {singleLineAddress(territory)}
                </div>
            );
        default: {
            const exhaustiveCheck: never = territory.type;
            throw new Error(`Unknown territory type: ${exhaustiveCheck}`);
        }
    }
};

const geoParamsToTerritoryParams = (
    geoParams: SearchGeoParams,
): UpsertUserTerritoryRequest => {
    const matchType: LocationSearchMatchType =
        geoParams.matchType as LocationSearchMatchType;
    switch (matchType) {
        case "distance":
            return {
                type: UserTerritoryType.Proximity,
                distance: geoParams.distance,
                address: geoParams.address,
                city: geoParams.city,
                county: geoParams.county,
                state: geoParams.state,
                zip: geoParams.zip,
            };
        case "city":
        case "county":
        case "state":
        case "zip":
            throw new InvalidOperationException(
                `Match type is not supported ${matchType}`,
            );
        default: {
            const exhaustiveCheck: never = matchType;
            throw new Error(`Unknown match type: ${exhaustiveCheck}`);
        }
    }
};

const territoryTypeToMatchType = (
    territoryType: UserTerritoryType,
): LocationSearchMatchType => {
    switch (territoryType) {
        case UserTerritoryType.Proximity:
            return "distance";
        default: {
            const exhaustiveCheck: never = territoryType;
            throw new Error(`Unknown territory type: ${exhaustiveCheck}`);
        }
    }
};
const territoryToGeoParams = (
    territory: GetUserProfileQuery["getUserProfile"]["territories"][number],
): SearchGeoParams => {
    return {
        address: territory.address,
        city: territory.city,
        county: territory.county,
        state: territory.state,
        zip: territory.zip,
        matchType: territoryTypeToMatchType(territory.type),
        distance: territory.distance,
    };
};

interface UserTerritoryFormProps {
    refetch: () => void;
    onClose: () => void;
    territory: GetUserProfileQuery["getUserProfile"]["territories"][number] | null;
}

const UserTerritoryForm: React.FC<UserTerritoryFormProps> = props => {
    const locale = useLocalizedStrings();
    const { onClose, refetch, territory } = props;

    const upsertTerritory = useUpsertUserTerritoryMutation();

    const handleTerritoryCommit = useCallback(
        async (geoParams: SearchGeoParams | null) => {
            if (geoParams) {
                const params = geoParamsToTerritoryParams(geoParams);
                await upsertTerritory.mutateAsync({
                    params,
                });
                refetch();
                onClose();
            } else {
                onClose();
            }
        },
        [onClose, refetch, upsertTerritory],
    );

    const initialGeoParams = useMemo(() => {
        if (territory) {
            return territoryToGeoParams(territory);
        }
        return {
            address: "",
            city: "",
            county: "",
            state: "",
            zip: "",
            matchType: "distance",
            distance: locale.conversions.toMeters(50 as LocalizedDistance),
        };
    }, [territory, locale.conversions]);

    return (
        <div className="bg-gray-100 p-4">
            <SearchGeoParamsEditor
                allowedDistances={[25, 50, 75, 100, 125, 150]}
                allowSpecifics={false}
                label="Enter your area details"
                initial={initialGeoParams}
                onCommit={handleTerritoryCommit}
                submitLabel="Save"
                clearLabel="Cancel"
            />
        </div>
    );
};
