import { CommunitySearch } from "components/community-search";
import { TimelineV2 } from "components/dashboard/timeline-v2";
import { SignatureRequestNew } from "components/files/signature-request/signature-request-new";
import { SignatureTemplate } from "components/files/signature-template/signature-template";
import { ManageRoot } from "components/manage";
import { SignwiseDashboard } from "components/signwise/signwise-dashboard";
import { SimpleSearch } from "components/simple-search/simple-search";
import { TaskDetail } from "components/tasks/v2/task-detail";
import { History } from "history";
import React, { FunctionComponent } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Provider } from "react-redux";
import { Route, Router, Switch } from "react-router";
import "react-widgets/styles.css";
import { Store } from "redux";
import { Persistor } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { UploadClients } from "../../upload-clients/upload-clients";
import ResetPassword from "../account";
import ResetPasswordEmail from "../account/reset-password-email";
import { ClientDashboardV2 } from "../clients/dashboard-client";
import { PipelineV2 } from "../dashboard/pipeline-v2";
import { Debug } from "../debug/debug";
import { ErrorBoundary } from "../error-boundary/error-boundary";
import { StandaloneFiles } from "../files/standalone";
import { RegionToggle } from "../region-toggle/select";
import { Reports } from "../reports";
import { StoreAwareQueryClientProvider } from "../store-aware-query-client-provider";
import { Tasksv2 } from "../tasks/v2/tasksv2";
import ShowClientFromRouteWrapper from "./../clients/show-client/from-route-wrapper";
import ShowCommunityFromRoute from "./../communities/show-community/from-route";
import { Eula } from "./../eula";
import { LocaleProvider } from "./../locale-provider/locale-provider";
import { Login } from "./../login";
import {
    ReferenceDashboard,
    ShowContactReference,
    ShowOrganizationReference,
} from "./../references";
import { Container } from "./container";
import { ProductBrandingContainer } from "./product-branding-container";
import "./tailwind.css";

interface AppProps {
    store: Store;
    history: History;
    persistor: Persistor;
}

export const App: FunctionComponent<AppProps> = ({ store, history, persistor }) => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <DndProvider backend={HTML5Backend} key={1}>
                    <StoreAwareQueryClientProvider>
                        {queryClient => (
                            <LocaleProvider>
                                <ProductBrandingContainer>
                                    {branding => (
                                        <ErrorBoundary branding={branding}>
                                            <Router history={history}>
                                                <Container queryClient={queryClient}>
                                                    <Switch>
                                                        <Route
                                                            exact
                                                            path="/"
                                                            component={PipelineV2}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/pipeline"
                                                            component={PipelineV2}
                                                        />
                                                        <Route
                                                            exact
                                                            component={TimelineV2}
                                                            path="/timeline"
                                                        />
                                                        <Route
                                                            exact
                                                            component={Login}
                                                            path="/login"
                                                        />
                                                        <Route
                                                            exact
                                                            component={Eula}
                                                            path="/license"
                                                        />
                                                        <Route
                                                            exact
                                                            component={Debug}
                                                            path="/debug"
                                                        />
                                                        <Route
                                                            exact
                                                            component={ResetPassword}
                                                            path="/account/reset-password"
                                                        />
                                                        <Route
                                                            exact
                                                            component={ResetPasswordEmail}
                                                            path="/account/reset"
                                                        />
                                                        <Route
                                                            exact
                                                            component={RegionToggle}
                                                            path="/region"
                                                        />
                                                        <Route
                                                            exact
                                                            path="/clients"
                                                            component={ClientDashboardV2}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/clients/active"
                                                            component={ClientDashboardV2}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/clients/ongoing"
                                                            component={ClientDashboardV2}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/clients/closed"
                                                            component={ClientDashboardV2}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/clients/show/:clientId"
                                                            component={
                                                                ShowClientFromRouteWrapper
                                                            }
                                                        />
                                                        <Route
                                                            exact
                                                            path="/clients/show/:clientId/:subpage"
                                                            component={
                                                                ShowClientFromRouteWrapper
                                                            }
                                                        />
                                                        <Route
                                                            exact
                                                            path="/communities"
                                                            component={CommunitySearch}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/communities/show/:communityId"
                                                            component={
                                                                ShowCommunityFromRoute
                                                            }
                                                        />
                                                        <Route
                                                            exact
                                                            path="/communities/show/:communityId/:subpage"
                                                            component={
                                                                ShowCommunityFromRoute
                                                            }
                                                        />
                                                        <Route
                                                            exact
                                                            path="/references"
                                                            component={ReferenceDashboard}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/references/organizations"
                                                            component={ReferenceDashboard}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/references/contacts"
                                                            component={ReferenceDashboard}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/references/communities"
                                                            component={ReferenceDashboard}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/references/organizationsv2"
                                                            component={ReferenceDashboard}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/references/organizations/show/:referenceId"
                                                            component={
                                                                ShowOrganizationReference
                                                            }
                                                        />
                                                        <Route
                                                            exact
                                                            path="/references/organizations/show/:referenceId/:subpage"
                                                            component={
                                                                ShowOrganizationReference
                                                            }
                                                        />
                                                        <Route
                                                            exact
                                                            path="/references/contacts/show/:referenceId"
                                                            component={
                                                                ShowContactReference
                                                            }
                                                        />
                                                        <Route
                                                            exact
                                                            path="/references/contacts/show/:referenceId/:subpage"
                                                            component={
                                                                ShowContactReference
                                                            }
                                                        />
                                                        <Route
                                                            exact
                                                            path="/tasks/:taskId"
                                                            component={TaskDetail}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/tasks"
                                                            component={Tasksv2}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/tasksv2"
                                                            component={Tasksv2}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/reports"
                                                            component={Reports}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/reports/show/:reportname"
                                                            component={Reports}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/reports/show/:reportname/:reportId"
                                                            component={Reports}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/clients/upload"
                                                            component={UploadClients}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/files"
                                                            component={StandaloneFiles}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/files/signwise"
                                                            component={SignwiseDashboard}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/files/:fileId/template"
                                                            component={SignatureTemplate}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/files/:fileId/signatures/:entityId/new"
                                                            component={
                                                                SignatureRequestNew
                                                            }
                                                        />
                                                        <Route
                                                            path="/settings"
                                                            component={ManageRoot}
                                                        />
                                                        <Route
                                                            path="/search"
                                                            component={SimpleSearch}
                                                        />
                                                    </Switch>
                                                </Container>
                                            </Router>
                                        </ErrorBoundary>
                                    )}
                                </ProductBrandingContainer>
                            </LocaleProvider>
                        )}
                    </StoreAwareQueryClientProvider>
                </DndProvider>
            </PersistGate>
        </Provider>
    );
};
