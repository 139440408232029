import { formatDateNoTime, LatLng, ReferralMappings } from "@sp-crm/core";
import { HoveredMapEntity } from "components/community-search/community-map/types";
import { Icon } from "components/icon";
import { ClampedText } from "components/ui/clamped-text";
import { defaultLinkStyle } from "components/ui/link";
import {
    AdvancedSearchEntityType,
    ReferenceOrganizationSearchQuery,
} from "generated/graphql";
import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { useCanSeeReferralSourceOwners, usePreferences } from "store/selectors/hooks";
import { twoLineFormattedAddress } from "util/address";
import { EmailLink } from "util/email-link";
import { PhoneSingleNumber } from "util/phone-display";
import { breakNewlines } from "util/text";
import { ReferenceOrganizationSearchCardAnswers } from "./reference-organization-search-card-answers";

interface ReferenceOrganizationSearchListProps {
    organizationSearchResults: ReferenceOrganizationSearchQuery["referenceOrganizationSearch"]["hits"];
    setHoveredOrganization: (hoveredOrganization: HoveredMapEntity | null) => void;
}

export const ReferenceOrganizationSearchList: React.FC<
    ReferenceOrganizationSearchListProps
> = props => {
    const { organizationSearchResults, setHoveredOrganization } = props;

    const handleEndHover = useCallback(() => {
        setHoveredOrganization(null);
    }, [setHoveredOrganization]);

    return (
        <ul className="space-y-4">
            {organizationSearchResults.map(org => (
                <li key={org.id}>
                    <ReferenceOrganizationSearchCard
                        business={org}
                        onHover={setHoveredOrganization}
                        onEndHover={handleEndHover}
                    />
                </li>
            ))}
        </ul>
    );
};

interface ReferenceOrganizationSearchCardProps {
    business: ReferenceOrganizationSearchQuery["referenceOrganizationSearch"]["hits"][0];
    onHover: (hoveredOrganization: HoveredMapEntity | null) => void;
    onEndHover: () => void;
}

const showAllContactsThreshold = 50;

const ReferenceOrganizationSearchCard: React.FC<
    ReferenceOrganizationSearchCardProps
> = props => {
    const { business, onHover, onEndHover } = props;

    const { showReferralLastUpdatedDateOnMainTable } = usePreferences();
    const assignedToVisible = useCanSeeReferralSourceOwners();
    const [showAllContacts, setShowAllContacts] = React.useState(false);

    const orgTypeMapping = useMemo(() => {
        if (business.organizationType && ReferralMappings[business.organizationType]) {
            return ReferralMappings[business.organizationType];
        }

        return null;
    }, [business.organizationType]);

    const handleMouseEnter = () => {
        let location: LatLng | null = null;
        if (business.latitude && business.longitude) {
            location = {
                lat: parseFloat(business.latitude),
                lng: parseFloat(business.longitude),
            };
        }
        onHover({ entityId: business.id, location });
    };

    const handleMouseLeave = () => {
        onEndHover();
    };

    const toggleShowAllContacts = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            setShowAllContacts(x => !x);
        },
        [],
    );

    const contacts = useMemo(() => {
        if (showAllContacts) {
            return business.contacts;
        }

        return business.contacts.slice(0, showAllContactsThreshold);
    }, [business.contacts, showAllContacts]);

    return (
        <div
            className="col-span-1 divide-y divide-gray-200 rounded md:rounded-lg bg-white shadow p-6"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <div className="flex-1 overflow-auto">
                <div className="flex space-x-2">
                    <div className="flex-1">
                        <h3 className="twoverride text-gray-900 text-lg">
                            {business.appLink ? (
                                <Link
                                    to={business.appLink}
                                    className="twoverride hover:underline">
                                    <div className="flex space-x-2 items-center">
                                        <div className="w-6">
                                            {business.entityType ===
                                            AdvancedSearchEntityType.ReferenceBusiness ? (
                                                <Icon name="39_Business" />
                                            ) : (
                                                <Icon name="57_Community" />
                                            )}
                                        </div>
                                        <div>{business.name || "(no name)"}</div>
                                    </div>
                                </Link>
                            ) : null}
                        </h3>
                        {orgTypeMapping ? (
                            <div className="text-sm font-medium mb-1">
                                {orgTypeMapping.category} &raquo;{" "}
                                {orgTypeMapping.humanReadable}
                            </div>
                        ) : null}
                        {twoLineFormattedAddress(
                            business.address,
                            business.city,
                            business.state,
                            business.zip,
                            "text-sm",
                        )}
                        <div className="text-sm">
                            {business.phone ? (
                                <PhoneSingleNumber
                                    displayName="office"
                                    phoneNumber={business.phone}
                                />
                            ) : null}
                            {business.fax ? (
                                <PhoneSingleNumber
                                    displayName="fax"
                                    phoneNumber={business.fax}
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="mt-4 border-t border-gray-200 pt-2">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
                        {business.summary ? (
                            <div className="sm:col-span-2">
                                <dt className="text-sm font-medium text-gray-500">
                                    Summary
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                    <ClampedText lines={3}>
                                        {breakNewlines(business.summary)}
                                    </ClampedText>
                                </dd>
                            </div>
                        ) : null}
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                                Number of referrals
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900">
                                {business.numberOfReferrals}
                            </dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                                Most recent referral
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900">
                                {formatDateNoTime(business.mostRecentReferral)}
                            </dd>
                        </div>
                        {showReferralLastUpdatedDateOnMainTable ? (
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">
                                    Last updated
                                </dt>
                                <dd className="mt-1 flex text-sm text-gray-900">
                                    {formatDateNoTime(business.updatedAt)}
                                </dd>
                            </div>
                        ) : null}
                        {assignedToVisible && business.assignedTo ? (
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">
                                    Assigned to
                                </dt>
                                <dd className="mt-1 flex text-sm text-gray-900">
                                    {business.assignedTo}
                                </dd>
                            </div>
                        ) : null}
                        <ReferenceOrganizationSearchCardAnswers organization={business} />
                        {contacts && contacts.length > 0 ? (
                            <div className="sm:col-span-2">
                                <dt className="text-sm font-medium text-gray-500">
                                    Contacts
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                    <ul className="grid grid-cols-1 gap-x-4 gap-y-4 xl:grid-cols-2">
                                        {contacts.map((contact, i) => (
                                            <li key={i} className="space-y-0.5">
                                                <Link
                                                    className="twoverride hover:underline"
                                                    to={contact.appLink}>
                                                    <div className="font-semibold">
                                                        {contact.name || "(no name)"}
                                                    </div>
                                                </Link>
                                                {contact.role ? (
                                                    <div className="text-gray-700">
                                                        {contact.role}
                                                    </div>
                                                ) : null}
                                                <EmailLink
                                                    email={contact.email}
                                                    entityType={
                                                        AdvancedSearchEntityType.Community
                                                    }
                                                    entityId={business.id}
                                                />
                                                {contact.cellPhone ? (
                                                    <PhoneSingleNumber
                                                        displayName="cell"
                                                        phoneNumber={contact.cellPhone}
                                                    />
                                                ) : null}
                                                {contact.phone ? (
                                                    <PhoneSingleNumber
                                                        displayName="alternate"
                                                        phoneNumber={contact.phone}
                                                    />
                                                ) : null}
                                            </li>
                                        ))}
                                    </ul>
                                    {business.contacts.length >
                                    showAllContactsThreshold ? (
                                        <button
                                            className={`${defaultLinkStyle} text-sm text-left mt-4`}
                                            onClick={toggleShowAllContacts}>
                                            {showAllContacts
                                                ? "Show less"
                                                : "Show more contacts"}
                                        </button>
                                    ) : null}
                                </dd>
                            </div>
                        ) : null}
                    </dl>
                </div>
            </div>
        </div>
    );
};
